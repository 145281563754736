<template>
  <div class="dialog" v-if="show">
    <img @click="show=false" src="../../assets/image/icon/close.png">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MyDialog",
  data(){
    return{
      show:false
    }
  },
  methods: {
    init(){
      this.show=true
    }
  }
}
</script>

<style scoped>
.dialog {
  width: 500px;
  height: 400px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 990;
  margin-left: -250px;
  margin-top: -200px;
}
img{
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>