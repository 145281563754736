<template>
  <div class="imgAndWord" :style="{backgroundImage:'url('+myBackground+')'}">
    <p :style="{color:color}">-{{ myName }}-</p>
  </div>
</template>

<script>
export default {
  name: "titleName",
  props: {
    myName: {
      type: String
    },
    myBackground:{},
    color: {
      type:String,
      default:'#ffffff'
    }
  }
}
</script>

<style scoped>
.imgAndWord {
  width: 100%;
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}

p {
  text-align: center;
  color: white;
  font-size: 29px;
  letter-spacing: 6px;
}

</style>