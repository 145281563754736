<template>
  <div style="width: 100%;position: fixed;top: 0;z-index: 999">
    <div id="heardBar" class="page">
      <div class="leftPage">
        <img v-if="pageName=='首页' && !g" src="../../assets/image/hlogow.png">
        <img v-else src="../../assets/image/hlogob.png">
        <!--        <img src="../../assets/image/icon/logo.png">-->
        <!--        <strong>-->
        <!--          <p class="name">陕西西咸新区服务贸易协会</p>-->
        <!--          <p class="englishName">-->
        <!--            Shanxi&nbsp;Xixian&nbsp;Aera&nbsp;Association&nbsp;of&nbsp;Trable&nbsp;in&nbsp;Services</p>-->
        <!--        </strong>-->
      </div>
      <div class="rightPage">
        <ul class="ul1">
          <li class="li1" v-for="item in value" :key="item.index">
            <router-link :to="{path:item.url,query:{name:item.child?item.child[0].name:item.name}}">
              {{ item.name }}
              <div class="redLine"></div>
            </router-link>
            <ul class="ul2">
              <li class="li2" v-for="item1 in item.child" :key="item1.index" @click="getTitleName(item1)">
                <router-link :to="{path:item1.url,query:{name:item1.name}}">{{ item1.name }}
                  <span v-if="item1.child" class="ico"><img src="../../assets/image/icon/rightRed.png"></span>
                </router-link>
                <ul class="ul3" v-if="item1.child">
                  <li class="li3" v-for="item2 in item1.child" :key="item2.index">
                    <router-link :to="item2.url">{{ item2.name }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

        </ul>
        <button style="cursor: pointer" @click="loginDialog">登录</button>
      </div>
    </div>
    <MyDiaLog v-if="show" ref="dialog">
      <div class="login">
        <input v-model="name" placeholder="请输入企业账号/手机号/邮箱">
        <input v-model="pwd" type="password" placeholder="请输入密码">
        <button style="cursor: pointer;color: #FFFFFF" @click="toFrontend">登录</button>
        <button class="enrollButton" @click="$router.push('/companiesEnroll?name=企业注册')">企业注册</button>
        <span style="color:red">{{ message }}</span>
      </div>
    </MyDiaLog>
  </div>
</template>

<script>
import MyDiaLog from './myDialog'

export default {
  name: "heardBar",
  data() {
    return {
      pageName: '首页',
      g: false,
      show: false,
      name: null,
      pwd: null,
      message: null,
      value: [
        {
          index: 0,
          name: '首页',
          url: '/'
        },
        {
          index: 1,
          name: '协会概况',
          url: '/introductionAssociation',
          child: [
            {
              index: 0,
              name: '协会简介',
              url: '/introductionAssociation',
            },
            {
              index: 1,
              name: '协会章程',
              url: '/constitution',
            },
            {
              index: 2,
              name: '组织架构',
              url: '/organizationalStructure',
            },
            {
              index: 3,
              name: '入会申请',
              url: '/applicationMembership',
            },
            {
              index: 4,
              name: '分支机构',
              url: '/institution',
            },
            {
              index: 5,
              name: '协会事迹',
              url: '/deeds',
            }
          ]
        },
        {
          index: 2,
          name: '行业资讯',
          url: '/NewDeal',
          child: [
            {
              index: 0,
              name: '新政解读',
              url: '/NewDeal',
              child: [
                {
                  index: 0,
                  name: '中央政策',
                  url: '/NewDeal',
                },
                {
                  index: 1,
                  name: '地方政策',
                  url: '/place',
                }
              ]
            },
            {
              index: 1,
              name: '行业动态',
              url: '/dynamic',
              child: [
                {
                  index: 0,
                  name: '协会动态',
                  url: '/dynamic',
                },
                {
                  index: 1,
                  name: '会员动态',
                  url: '/member',
                }
              ]
            }
          ]
        },
        {
          index: 3,
          name: '智库专家',
          url: '/mien',
          child: [
            {
              index: 0,
              name: '专家风采',
              url: '/mien',
            },
            {
              index: 1,
              name: '专家文献',
              url: '/literature',
            },
            {
              index: 2,
              name: '行业报告',
              url: '/report'
            }
          ]
        },
        {
          index: 4,
          name: '协会服务',
          url: '/important',
          child: [
            {
              index: 0,
              name: '重要活动',
              url: '/important',
            },
            {
              index: 1,
              name: '培训活动',
              url: '/training',
            },
          ]
        },
        {
          index: 5,
          name: '协会会员',
          url: '/synopsis',
          child: [
            {
              index: 0,
              name: '会员简介',
              url: '/synopsis',
            },
            {
              index: 1,
              name: '会企供求',
              url: '/supply',
            }
          ]
        },
        {
          index: 6,
          name: '行业自律',
          url: '/disciplineContent'
        },
        {
          index: 7,
          name: '联系我们',
          url: '/connection'

        },
      ]
    }
  },
  components: {
    MyDiaLog
  },
  watch:{
    '$route'() {
      this.pageName = this.$route.query.name
    }
  },
  mounted() {
    if (this.$route.path == '/') {
      this.pageName = '首页'
      let ul2 = document.getElementsByClassName('ul2')
      for (let i = 0; i < ul2.length; i++) {
        ul2[i].style.background = '#050001'
      }
      let ul3 = document.getElementsByClassName('ul3')
      for (let i = 0; i < ul3.length; i++) {
        ul3[i].style.background = '#050001'
      }

    } else {
      this.pageName = this.$route.query.name
    }
    window.addEventListener('scroll', this.scrollEvent)
  },
  methods: {
    scrollEvent() {
      let h = document.documentElement.scrollTop
      let ele = document.getElementById('heardBar')
      if (this.pageName!='首页' || (this.pageName == '首页' && h > 800)) {
        ele.style.backgroundColor = "#ffffff"
        ele.style.opacity = '1'
        ele.style.color = '#050001'
        this.g = true

        let ul2 = document.getElementsByClassName('ul2')
        for (let i = 0; i < ul2.length; i++) {
          ul2[i].style.background = '#ffffff'
        }
        let ul3 = document.getElementsByClassName('ul3')
        for (let i = 0; i < ul3.length; i++) {
          ul3[i].style.background = '#ffffff'
        }


      } else if (this.pageName == '首页' && h <= 800) {
        ele.style.backgroundColor = ''
        ele.style.opacity = ''
        ele.style.color = ''
        this.g = false

        let ul2 = document.getElementsByClassName('ul2')
        for (let i = 0; i < ul2.length; i++) {
          ul2[i].style.background = '#050001'
        }
        let ul3 = document.getElementsByClassName('ul3')
        for (let i = 0; i < ul3.length; i++) {
          ul3[i].style.background = '#050001'
        }
      }
    },
    loginDialog() {
      this.show = true
      this.message = ''
      this.$nextTick(() => {
        this.$refs.dialog.init()
      })
    },
    getTitleName(e) {
      this.$emit('getTitleName', e)
    },
    toFrontend() {
      this.$http.post('login/login?name=' + this.name + '&pwd=' + this.pwd,)
          .then((res) => {
            if (res.data.message == 'ok') {
              if (res.data.token != null) {
                if (res.data.token.userType == 1) {
                  location.href = this.$constContent.imgUrl + '#/enterpriseList?t=' + res.data.tokenId
                } else {
                  location.href = this.$constContent.imgUrl + '#/CompaniesInformation?t=' + res.data.tokenId
                }
              } else {
                this.message = "提示：已有用户登录！请稍后..."
              }
            } else {
              this.message = '提示:' + res.data.message
            }
          })
    }
  }

}
</script>

<style scoped>

.enrollButton{
  width: 100px!important;
  line-height: 30px!important;
  height: 30px !important;
  background: #FFFFFF !important;
  border: none !important;
  margin: 0 !important;
  font-size: 14px !important;
}
.page {
  font-size: 14px;
  width: 80%;
  padding: 0 10%;
  height: 130px;
  color: #ffffff;
  background: #050001;
  opacity: 0.5;
  z-index: 999;
}

.leftPage {
  width: calc(35% - 30px);
  /*margin: 28px 0 22px 0;*/
  /*height: 80px;*/
  height: 130px;
  float: left;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  cursor: default;
}

.rightPage {
  width: 65%;
  margin-top: 52px;
  height: 68px;
  float: left;
}

strong {
  display: block;
  margin-top: 22px
}

.name {
  font-size: 21px;
  line-height: 21px;
  margin: 0 0 8px 0;
}

.englishName {
  font-size: 12px;
  line-height: 12px;
  margin: 0
}

img {
  width: 100%;
  /*margin-top: 9px;*/
  /*height: 58px;*/
  /*width: 58px;*/
}

.ul1 {
  width: 90%;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  float: left;
  display: flex;
  justify-content: center;
}

.li1 {
  width: 12.5%;
  line-height: 40px;
  font-size: 16px;
  text-align: center;

}

.ul2 {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  display: none;
  background: #ffffff;
}


.li1 > a:hover {
  color: red;
  font-weight: 600;
  padding-bottom: 8px;
}

.li1:hover .redLine {
  display: block;
}

.redLine {
  display: none;
  width: 30px;
  height: 2px;
  background-color: red;
  margin: auto;
}

.li1:hover .ul2 {
  display: block;
}

.li2 {
  width: 100%;
  text-align: center;
  list-style: none;
  font-size: 14px;
  line-height: 40px;
  margin: 0 auto;
  position: relative;
}

.ul3 {
  width: 100%;
  position: absolute;
  margin-top: -40px;
  margin-left: 100%;
  display: none;
  border-radius: 0 0 3px 0;
}

.li2:hover .ul3 {
  list-style: none;
  padding: 0;
  display: block;
}

.ico {
  display: none;
}

.ico img {
  width: 15px;
  height: 15px;
}

.li2:hover .ico {
  display: inline-flex;
  width: 15px;
  padding-top: 10px;
  line-height: 40px;
}


.li3 {

}


@media screen and (max-width: 1375px) {
  .name {
    font-size: 18px
  }

  .englishName {
    font-size: 8px
  }

  ul > li {
    font-size: 16px
  }
}

@media screen and (max-width: 1198px) {
  ul > li {
    font-size: 14px
  }
}

a {
  height: 68px;
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: red;
  font-weight: 700;
}

button {
  float: left;
  width: 10%;
  height: 41px;
  line-height: 41px;
  margin-top: 8px;
  font-size: 16px;
  color: inherit;
  border: 1px solid #666666;
  background: rgba(0, 0, 0, 0);
  border-radius: 3px;
}

.login {
  width: 80%;
  margin: 50px 10%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.login input {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 25px 0;
  border: 1px solid #666666;
  border-radius: 3px;
  height: 30px;
}

.login button {
  width: 100%;
  height: 52px;
  margin: 15px 0 10px;
  background: linear-gradient(to right, #fc4a1a, #f7b733);
  border: none;
  border-radius: 4px;
}

span {
  font-size: 15px;
  color: #999;
}


</style>