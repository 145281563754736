<template>
  <div class="footPage">
    <div class="footer">
      <div class="block">
        <h3>联系电话</h3>
        <ul>
          <li style="color: red">029-8918-3673</li>
          <li>(周一至周五 9:00 -- 18:00)</li>
          <li>微信公众号</li>
          <li class="fu">
            <img class="imgBlock" src="../../assets/image/erweima.png">
            服订
          </li>
        </ul>
      </div>
      <div class="block">
        <h3>快速入口</h3>
        <ul>
          <li>
            <router-link to="/introductionAssociation?name=协会简介">协会概况</router-link>
            <router-link to="/applicationMembership?name=入会申请">入会申请</router-link>
          </li>
          <li><router-link to="/organizationalStructure?name=组织架构">组织架构</router-link>
            <router-link to="/mien?name=专家风采">智库专家</router-link></li>
          <li><router-link to="/important?name=重要活动">重要活动</router-link>
            <router-link to="/connection?name=联系我们">联系我们</router-link></li>
          <li><router-link to="/institution?name=分支机构">分支机构</router-link></li>
        </ul>
      </div>
      <div class="block">
        <h3>联系我们</h3>
        <ul>
          <li>Email：xxxqfmxh@163.com</li>
          <li>邮编：712000</li>
          <li>地址：陕西省西咸新区秦汉新城秦汉文创大厦5层518室</li>
        </ul>
      </div>
    </div>
    <p>
      Copyright 陕西西咸新区服务贸易协会 陕西西咸服务贸易网 版权所有 陕ICP备2021006524号
    </p>
  </div>
</template>

<script>
export default {
  name: "myFooter"
}
</script>

<style scoped>
.footPage {
  width: 100%;
  height: 456px;
  margin-top: 20px;
}
.footer{
  width: 80%;
  padding: 20px 10% 0 10%;
  height: 406px;
  background: #f8f8f8;
}

h3 {
  border-bottom: 1px solid #999999;
}

.block {
  width: 33.3%;
  float: left;
  font-size: 14px;
}

ul {
  list-style: none;
  width: 50%;
  padding: 0;
}

ul li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul li a {
  width: 50%;
  color: #111111;
  text-decoration: none;
  cursor: pointer
}

img {
  margin: 20px 0;
}
p{
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  background: #161823;
}
.fu{
  position: relative;
  color: red;
  letter-spacing: 30px;
}
.fu:hover .imgBlock{
  display: block;
}
.imgBlock{
  position: absolute;
  display: none;
  top: -100px;
}
</style>
