<template>
<div>
  <heard-bar class="heard" v-on:getTitleName="getTitleName"></heard-bar>
  <div class="title">
    <slot name="title"></slot>
  </div>
  <div class="content">
    <slot name="content"></slot>
  </div>
  <my-footer></my-footer>
</div>
</template>

<script>
import heardBar from "@/components/index/heardBar";
import myFooter from "@/components/index/myFooter";
export default {
  name: "heardAndFooter",
  components:{
    heardBar,
    myFooter
  },
  data(){
    return{

    }
  },
  methods:{
    getTitleName(val){
      this.$emit('getTitleName',val.name)
    }
  }
}
</script>

<style scoped>
.heard >>> .page, .heard >>> ul li:hover ul{
  background: #ffffff;
  color: #050001;
  opacity: 1;
}
.heard >>>.rightPage button{
  color: #050001;
}
.heard>>> .li2{
  background-color: rgba(255,255,255,0.5);
  color: #050001;
  border-radius: 5px;
}
.heard>>>.ul2{
  border-radius: 5px;
}

.content {
  width: 80%;
  margin: 0 10%;
}
.title{
  margin-top: 130px;
}
</style>